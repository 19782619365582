<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技荣获复旦大学管理学院“年度合作伙伴”奖
              </p>
              <div class="fst-italic mb-2">2024年1月30日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/74/01-复旦.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技受邀参加复旦大学管理学院2024年校企合作新春交流会，并喜获“2023年度合作伙伴”奖。希望通过双方的互动交流和紧密合作，碰撞出更多创新灵感，实现校企共赢发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/74/02-获奖.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    会上，复旦大学管理学院院长陆雄文教授介绍了学院的整体办学情况和近年来取得的成绩，强调了校企合作对于企业院校人才培养的重要影响。多年来，复旦管院一直致力于发展高品质的商科教育，希望通过加强与企业的创新联动，以“合作共赢”为切入点，努力建立稳定关系，形成校企合作良好局面。
                  </p>
                  <p>
                    非凸科技已连续两年参与支持复旦管院数据科学与商业分析D-Lab实践项目，与院校导师共同指导学生完成实践课题。在项目过程中，非凸科技联合创始人&CTO李佐凡担任职业导师，带领学生进行为期3个月的项目实习，通过综合运用机器学习等前沿技术对策略研究问题进行深入探索，不仅成功解决了业界实际问题，还极大地提升了学生的创新与实践能力。
                  </p>
                  <p>
                    随着社会的发展，对复合型人才的需求日益增加，具备多元化的背景和丰富的实践经验，正是未来人才的发展方向。非凸科技与复旦大学在专业共建、联合培养、共同研究等方面还将进一步深化合作。
                  </p>
                  <p>
                    一直以来，非凸科技致力于推动科技创新与人才培养，也希望与更多高校在“产学研”领域展开深度合作，培养创新型、复合型高素质金融科技人才，以更好地满足行业发展需求。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News74",
};
</script>

<style></style>
